.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom */
#table-full table,
#table-full td,
#table-full th {
  border: 1px solid #d3d3d3;
  text-align: center;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}

table {
  width: 100%;
  border-collapse: collapse;
}

#table-no-tb table,
#table-no-tb td,
#table-no-tb th {
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
#table-full td,
#table-no-tb td {
  height: 50px; /* content height 50px */
  padding: 0;
}

.result-number-big {
  font-size: 28px;
  font-weight: bold;
  color: #cd0404;
  font-family: 'Arial, Tahoma, Helvetica', sans-serif;
}

.text-red {
  color: #cd0404;
  font-size: 26px;
  font-weight: bold;
}

.result-number-black {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  font-family: 'Arial, Tahoma, Helvetica', sans-serif;
}

.border-tb-right {
  border-right: 1px solid #d3d3d3;
}

.content-number-center {
  height: 50px; /* content height 50px */
  justify-content: center;
  align-items: center;
  display: flex;
}

/* Loading */
.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #118a0f;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-bottom: 4px solid #ff3d00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
